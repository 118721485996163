import React from "react";
import ReactDOM from 'react-dom/client';
import './css/styles.css';
import App from "./js/App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

//Add these lines
Sentry.init({
    dsn: "https://d4382581e3b145f0829a2827b7ee7e12@o1327675.ingest.sentry.io/6746086", //paste copied DSN value here
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0, //lower the value in production
  });
  

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);

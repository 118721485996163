import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from "react";
import logo from './logo.svg';
import ReCAPTCHA from 'react-google-recaptcha'

function App() {

  const grecaptchaObject = window.grecaptcha;

  useEffect(() => {
    init();
    },[]);

  return (
    <div>
    <div className="wrapper">
      <nav className="navbar navbar-expand-lg navbar-dark bg-white">
        <div className="container">
          <p id="logo-p"><img id="logo-img" src={logo} ></img></p>
        </div>
      </nav>

      <div className="container">
        <div className="text-left mt-5">
          <h1>Payment Details</h1>
          <p className="lead">Enter your payment details below.</p>
		  <p><i>Please note: We accept VISA or Mastercard. Debit card transactions are subject to a 0.46% fee, and credit card payments incur a 0.81% fee.</i></p>
      <p><i>Got both electricity and gas with us? You'll need to pay for each service separately using the respective account numbers. You can make payments hassle-free by <a href="https://energylocals.com.au/pay" target="_blank" rel="noreferrer">setting up Direct Debit</a>.</i></p>
          <p>&nbsp;</p>

          <form id="payment-form">
            <div className="form-group">
              <label htmlFor="amount">Payment amount</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <input type="text-left" className="form-control" id="amount" required></input>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="accountNumber">Account number</label>
              <input type="dollar" className="form-control" id="accountNumber" required></input>
            </div>

            <div data-quickstream-api="creditCardContainer"></div>
            <div className="form-group">
              <label htmlFor="email">Please enter your email if you would like a receipt of this payment sent to you.</label>
              <input type="text" className="form-control" id="email"></input>
            </div>
            <div className="form-group">
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECATPCHA_KEY} grecaptcha={grecaptchaObject}/></div>

            <div className="form-group">
              <button id="submit" type="submit" className="btn btn-danger" data-container="body" data-placement="right">Make Payment</button>
            </div>
          </form>
        </div>

        <p id="submit-error" className="alert alert-danger" role="alert"></p>
      </div>

      <p>&nbsp;</p>
    </div>
    <footer>
      <div className="container">
        <span className="text-muted">© Copyright <span id="year">2022</span> Arcline by RACV.</span>
      </div>
    </footer>
    </div>
  )
}

function init(){

  let callback = function( errors, data ) {
    if ( errors ) {
        console.log(errors)
    }
    else {
        console.log( data );
    }
  }

/* eslint-disable */
  var year = new Date().getFullYear();
  document.getElementById("year").innerHTML = year;

  var urlParams = new URLSearchParams(window.location.search);
  var accountNumber = '';
  var amount = '';

  document.getElementById("accountNumber").value = accountNumber;
  document.getElementById("amount").value = amount;

  var uuid = uuidv4();
  console.log("Idempotency key: " + uuid);

  if (window.QuickstreamAPI) {
    //Credit Card input frame details

    QuickstreamAPI.init( {
        publishableApiKey:process.env.REACT_APP_QUICKSTREAM_API
    } );


    var trustedFrame;
    var inputStyle = {
      height: "34px",
      padding: "0px 12px",
      "font-size": "14px",
      border: "1px solid #ccc",
      "border-radius": "2px"
    };
    var options = {
      config: {
        supplierBusinessCode: "ARCLINE"
      },
      iframe: {
        width: "100%",
        height: "100%",
        style: {
          "font-size": "14px",
          "line-height": "24px",
          border: "1px solid #dedede",
          "border-radius": "2px",
          "margin-bottom": "0.75rem",
          "min-height": "400px",
          padding: "1.5rem",
          width: "100%",
          "background-color": "white"
        }
      },
      showAcceptedCards: true,
      cardholderName: {
        style: inputStyle,
        label: "Name on card"
      },
      cardNumber: {
        style: inputStyle,
        label: "Card number"
      },
      expiryDateMonth: {
        style: inputStyle
      },
      expiryDateYear: {
        style: inputStyle
      },
      cvn: {
        hidden: false,
        label: "Security code"
      },
      body: {
        style: {}
      }
    };

    QuickstreamAPI.creditCards.createTrustedFrame(options, function ( errors,
        data
      ) {
        if (errors){
          console.log(errors);
        }else{
          trustedFrame = data.trustedFrame;
        }
      });


    var form = document.getElementById("payment-form");
    form.addEventListener("submit", function (event) {
      event.preventDefault();
      var email = document.getElementById("email").value;
      email = email.replace(' ;*','');

      var errorAlert = document.getElementById("submit-error");
      errorAlert.style.display = "none";
      errorAlert.innerHTML = "";

      //checks completed/valid recaptcha response
      var captchaResp = grecaptcha.getResponse();
      if(captchaResp.length === 0){
        errorAlert.innerHTML = "Please complete the reCAPTCHA verification."; //Displays error to user
        errorAlert.style.display = "block";
        return;
      }

      //checks valid number to 2 decimal places
      amount = document.getElementById("amount").value;
      let amount_pattern = /((^0[.][0-9]{2}$)|(^[1-9][0-9]*[.][0-9]{2}$)|(^[1-9][0-9]*$))/g;
      if (!amount_pattern.test(amount)){
          errorAlert.innerHTML = "Please enter a valid amount"; //Displays error to user
          errorAlert.style.display = "block";
          return;
      };

      //checks valid account number (starts with 9 followed by 6 digits)
      accountNumber = document.getElementById("accountNumber").value;
      let account_pattern = /^9[0-9]{6}$/g;
      if (!account_pattern.test(accountNumber)){
          errorAlert.innerHTML = "Please enter a valid account number"; //Displays error to user
          errorAlert.style.display = "block";
          return;
      }

      trustedFrame.submitForm(function (errors, data) {
        if (errors) {
          console.log("Errors: " + JSON.stringify(errors));
        } else {
          let token = data.singleUseToken.singleUseTokenId;
          QuickstreamAPI.creditCards.appendTokenToForm(form, token); //stores credit card form details with token
          console.log("Single-Use Token: " + token);

          var requestData = {"singleUseTokenId": token, "principalAmount": amount, "customerReferenceNumber": accountNumber, "supplierBusinessCode": "ARCLINE", "idempotencyKey": uuid, "recaptchaToken": captchaResp, "email":email};

          //sends request data to backend via api
          fetch(process.env.REACT_APP_API_SRC, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
          })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            if(data.status !== "Approved"){
              //Display unsuccessful payment message
              var form = document.getElementById("payment-form");
              form.innerHTML = '<div class="alert alert-danger" role="alert">Sorry, your payment was declined by your financial institution.</div>';
            }
            else{
              //Display successful payment message
              var form = document.getElementById("payment-form");
              form.innerHTML = '<div class="alert alert alert-success" role="alert">Thanks! Your payment of ' + data.principalAmount + ' was successful.<br><br> Your receipt number is ' + data.receipt + '.</div>';
            }
          })
          .catch((error) => {
            //Display error message
            console.error('Error:', error);
            var form = document.getElementById("payment-form");
            form.innerHTML = '<div class="alert alert-danger" role="alert">Sorry. Something went wrong. Please contact us for help.</div>';
          });
        }
      });
    });
  } else {
    var form = document.getElementById("payment-form");
    form.innerHTML = '<div class="alert alert-danger" role="alert">The service is currently unavailable</div>';
  }
}

export default App;
